import { Auth, Subscription, UserProfile } from 'src/data'
import { userSlice } from '../reducers/user'
import { Dispatch } from 'redux'
import { segment } from 'src/lib/analytics'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { InvoiceItem, ManagedCityBuilding, StripeAccount, TrackRentItem } from 'src/@types/manager'
import { buildingSlice } from '@redux/reducers/building'
import { AppState } from '@redux/store'
import ACTIONS from '@redux/constants'

const { USER } = ACTIONS

export const setCurrentUserProfile = (user, token: string) => async (dispatch: Dispatch) => {
  dispatch(userSlice.actions.setIsLoggedIn(true))
  dispatch(userSlice.actions.setToken(token))
  dispatch(userSlice.actions.setUserProfile(user))

  dispatch(buildingSlice.actions.resetBuildingSlice())

  segment.setUser(user.uid, user.email)
}

export const fetchUserPermissions = (token: string) => async (dispatch: Dispatch) => {
  const permissions = await Auth.getPermissions(token)
  dispatch(userSlice.actions.setUserPermissions(permissions))
}

export const editCurrentUserName =
  (uid: string, newName: string, token: string) => async (dispatch) => {
    const userProfile = await UserProfile.updateName(uid, newName, token)
    dispatch(userSlice.actions.setUserProfile(userProfile))
  }

export const setIsLoggedIn = (val: boolean) => async (dispatch: Dispatch) => {
  dispatch(userSlice.actions.setIsLoggedIn(val))
  if (!val) {
    dispatch(userSlice.actions.setUserProfile(null))
    dispatch(userSlice.actions.setToken(null))
    dispatch(userSlice.actions.setUserManagedBuilding(null))
    dispatch(userSlice.actions.setUserManagedUnits(null))
    dispatch(userSlice.actions.setUserManagedListings(null))
    dispatch(userSlice.actions.setUserManagedTenants(null))
    dispatch(userSlice.actions.setUserManagedApplications(null))
  }
}

export const getUserManagedBuildings = createAsyncThunk(
  USER.GET_BUILDINGS,
  async ({ token, page = 1, all = false }: { token: string; page?: number; all?: boolean }) => {
    const response = await UserProfile.getUserManagedBuildings(token, page, all)
    return { ...response, page, all }
  }
)

export const getUserManagedUnits = createAsyncThunk(
  USER.GET_UNITS,
  async ({ token, page = 1, all = false }: { token: string; page?: number; all?: boolean }) => {
    const response = await UserProfile.getUserManagedUnits(token, page, all)
    return { ...response, page, all }
  }
)

export const getUserManagedListings = createAsyncThunk(
  USER.GET_LISTINGS,
  async ({ token, page = 1, all = false }: { token: string; page?: number; all?: boolean }) => {
    const response = await UserProfile.getUserManagedListings(token, page, all)
    return { ...response, page, all }
  }
)
export const getUserManagedTenants = createAsyncThunk(
  USER.GET_TENANTS,
  async ({ token, page = 1, all = false }: { token: string; page?: number; all?: boolean }) => {
    const response = await UserProfile.getUserManagedTenants(token, page, all)
    return { ...response, page, all }
  }
)

export const getUserManagedApplications = createAsyncThunk(
  USER.GET_APPLICATIONS,
  async ({ token, page = 1, all = false }: { token: string; page?: number; all?: boolean }) => {
    const response = await UserProfile.getUserManagedApplications(token, page, all)
    return { ...response, page, all }
  }
)

export const addAdditionalBuildings =
  (buildings: ManagedCityBuilding[]) => async (dispatch: Dispatch) => {
    dispatch(userSlice.actions.addAdditionalBuildings(buildings))
  }

export const updateBuildingsCurrentPage = (page: number) => async (dispatch: Dispatch) => {
  dispatch(userSlice.actions.setBuildingsCurrentPage(page))
}

export const updateApplicationsCurrentPage = (page: number) => async (dispatch: Dispatch) => {
  dispatch(userSlice.actions.setApplicationsCurrentPage(page))
}

export const updateListingsCurrentPage = (page: number) => async (dispatch: Dispatch) => {
  dispatch(userSlice.actions.setListingsCurrentPage(page))
}

export const updateTenantsCurrentPage = (page: number) => async (dispatch: Dispatch) => {
  dispatch(userSlice.actions.setTenantsCurrentPage(page))
}

// export const getUserManagedLeases = (token: string) => async (dispatch: Dispatch) => {
//   const leases = await UserProfile.getUserManagedUnits(token)
//   dispatch(userSlice.actions.setUserManagedLeases(leases))
// }

export const isChatlioReady = (val: boolean) => (dispatch: Dispatch) => {
  dispatch(userSlice.actions.setIsChatlioReady(val))
}

export const setStripeAccounts = (accounts: StripeAccount[]) => (dispatch: Dispatch) => {
  dispatch(userSlice.actions.setStripeAccounts(accounts))
}

export const fetchStripeAccounts = createAsyncThunk(
  USER.GET_STRIPE_ACCOUNTS,
  async (token: string) => {
    return await Subscription.getStripeAccounts(token)
  }
)

export const fetchManagementSummary = createAsyncThunk(
  USER.GET_MANAGEMENT_SUMMARY,
  async (token: string) => {
    return await UserProfile.getManagementSummary(token)
  }
)

export const setCurrentInvoice = (invoiceItems: TrackRentItem[] | null) => (dispatch: Dispatch) => {
  dispatch(userSlice.actions.setCurrentInvoice(invoiceItems))
}

export const updateApplication =
  (application) => (dispatch: Dispatch, getState: () => AppState) => {
    const {
      currentUser: { userManagedApplications },
    } = getState()
    const updatedApps = userManagedApplications?.map((app) => {
      return app.id == application.id ? application : app
    })
    dispatch(userSlice.actions.setUserManagedApplications(updatedApps))
  }

export const addNewTenant = (managedUnit) => (dispatch: Dispatch, getState: () => AppState) => {
  const {
    currentUser: { userManagedTenants },
  } = getState()
  let updatedUnits = []
  if (userManagedTenants.filter((ten) => ten.id == managedUnit.id).length > 0) {
    updatedUnits = userManagedTenants.map((ten) => {
      return ten.id == managedUnit.id ? managedUnit : ten
    })
  } else {
    updatedUnits = [...(userManagedTenants || []), managedUnit]
  }
  dispatch(userSlice.actions.setUserManagedTenants(updatedUnits))
}
