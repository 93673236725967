import { UserPermissions, RoleType } from 'src/@types/manager'
import { apiFetch } from './Api'

const CHECK_ENDPOINT = 'https://api.openigloo.com/users/check/'
type CHECK_ENDPOINT_RES_TYPE = { existing: boolean; verified: boolean }

export async function loginWithPasswordV1(email: string, password: string) {
  const response = await fetch(CHECK_ENDPOINT, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  })
  let json: CHECK_ENDPOINT_RES_TYPE = await response.json()
  return json.existing && json.verified
}

export async function checkEmailV1(email: string) {
  const response = await fetch(CHECK_ENDPOINT, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
    }),
  })
  let json: CHECK_ENDPOINT_RES_TYPE = await response.json()
  return json.existing
}

export async function getPermissions(token): Promise<UserPermissions> {
  return await apiFetch({
    path: `/management/`,
    authToken: token,
  })
}

export async function setRole(role: RoleType, description = null): Promise<UserPermissions> {
  return await apiFetch({
    path: `/management/set_role/`,
    method: 'POST',
    data: {
      role,
      description,
    },
  })
}

export async function addApplicationsMgmt(): Promise<UserPermissions> {
  return await apiFetch({
    path: `/management/request_applications_management/`,
    method: 'POST',
    data: {
      role: "agent",
      description: null,
    },
  })
}
