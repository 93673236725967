import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  ManagedCityBuilding,
  ManagedCityUnit,
  DraftUnit,
  Amenity,
  BuildingOpendataDetails,
  Review,
} from 'src/@types/manager'
import {
  setCurrentBuildingReview,
  setManagedUnitsForCurrentBuilding,
  setPaginatedManagedUnitsForCurrentBuilding,
  setCurrentBuildingOpendata,
} from '@redux/actions'
import { BuildingOverview } from '@openigloo/types'

export type BuildingState = {
  currentBuilding: ManagedCityBuilding | null
  currentBuildingReview: Review[] | null
  managedUnits: ManagedCityUnit[] | null
  paginatedManagedUnits: { count: number; items: Record<number, ManagedCityUnit[]> }
  currentUnit: ManagedCityUnit | DraftUnit | null
  currentUnitApplications: any | null
  cityAmenities: Amenity[] | null
  opendata?: Record<string, BuildingOpendataDetails> | null
  overview: BuildingOverview
}

const initialState: BuildingState = {
  currentBuilding: null,
  currentBuildingReview: null,
  managedUnits: null,
  paginatedManagedUnits: {
    count: 0,
    items: {},
  },
  currentUnit: null,
  currentUnitApplications: null,
  cityAmenities: null,
  opendata: null,
  overview: null
}

export const buildingSlice = createSlice({
  name: 'currentBuilding',
  initialState,
  reducers: {
    resetBuildingSlice(state) {
      state = initialState
    },
    setCurrentBuilding(state, action: PayloadAction<ManagedCityBuilding>) {
      state.currentBuilding = action.payload
    },
    setCurrentBuildingReview(state, action: PayloadAction<Review[]>) {
      state.currentBuildingReview = action.payload
    },
    setOverview(state, action: PayloadAction<BuildingOverview>) {
      state.overview = action.payload
    },
    setManagedUnits(state, action: PayloadAction<ManagedCityUnit[]>) {
      state.managedUnits = action.payload
    },
    setCurrentUnit(state, action: PayloadAction<ManagedCityUnit | DraftUnit | null>) {
      state.currentUnit = action.payload
    },
    setCurrentUnitApplications(state, action: PayloadAction<any>) {
      state.currentUnitApplications = action.payload
    },
    setCityAmenities(state, action: PayloadAction<Amenity[]>) {
      state.cityAmenities = action.payload
    },
    resetPaginatedManagedUnits(state) {
      state.paginatedManagedUnits = { count: 0, items: {} }
    },
    cleanupPaginatedManagedUnits(state, action: PayloadAction<string[]>) {
      const cleanedUpItems = { ...state.paginatedManagedUnits.items }
      action.payload.forEach((item) => delete cleanedUpItems[item])
      state.paginatedManagedUnits.items = cleanedUpItems
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCurrentBuildingReview.fulfilled, (state, action: any) => {
        return {
          ...state,
          currentBuildingReview: action.payload,
        }
      })
      .addCase(setManagedUnitsForCurrentBuilding.fulfilled, (state, action: any) => {
        const filteredUnits = action.payload
        return {
          ...state,
          managedUnits: filteredUnits,
        }
      })
      .addCase(setPaginatedManagedUnitsForCurrentBuilding.fulfilled, (state, action: any) => {
        const { page, count, results } = action.payload
        return {
          ...state,
          paginatedManagedUnits: {
            count,
            items: { ...state.paginatedManagedUnits.items, [page]: results },
          },
        }
      })
      .addCase(setCurrentBuildingOpendata.fulfilled, (state, action: any) => {
        return {
          ...state,
          opendata: action.payload,
        }
      })
  },
})

export default buildingSlice.reducer
