import { createSlice } from '@reduxjs/toolkit'

import { LoadingState } from 'src/@types/manager'

const initialState: LoadingState = {
  fetchingActions: {},
  errorActions: {},
}

const isPendingAction = (action) => {
  return action.type.endsWith('/pending')
}

const isFulfilledAction = (action) => {
  return action.type.endsWith('/fulfilled')
}

const isRejectedAction = (action) => {
  return action.type.endsWith('/rejected')
}

export const loadingSlice = createSlice({
  name: 'currentLoading',
  initialState,
  reducers: {
    clearError: (state, action) => {
      action.payload.map((requestName) => (state.errorActions[requestName] = ''))
    },
    clearAllErrors: (state) => {
      state.errorActions = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPendingAction, (state, action) => {
        const { type } = action
        const matches = /(.*)\/(pending)/.exec(type)
        if (matches) {
          const [, requestName] = matches
          state.fetchingActions[requestName] = true
        }
      })
      .addMatcher(isFulfilledAction, (state, action) => {
        const { type } = action
        const matches = /(.*)\/(fulfilled)/.exec(type)
        if (matches) {
          const [, requestName] = matches
          state.fetchingActions[requestName] = false
          state.errorActions[requestName] = ''
        }
      })
      .addMatcher(isRejectedAction, (state, action) => {
        const { type, error } = action
        const matches = /(.*)\/(rejected)/.exec(type)
        if (matches) {
          const [, requestName] = matches
          state.fetchingActions[requestName] = false
          state.errorActions[requestName] = error
        }
      })
  },
})

export default loadingSlice.reducer
