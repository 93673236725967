import { apiFetch, APIFetchArgs } from './Api'
import {
  OwnerComment,
  Review,
  Building,
  Contact,
  BuildingAggResponse,
  BuildingOverview,
  AggRating,
  Rating,
  ContactAggResponse,
} from '@openigloo/types'
import snakecaseKeys from 'snakecase-keys'
import { ManagedCityBuilding, BuildingOpendataDetails } from 'src/@types/manager'

const DEFAULT_CITY = 'nyc'

export async function getBuilding(
  id: string,
  city: string = 'nyc',
  slug: string | null = null
): Promise<Building> {
  let path = `/${city}/buildings/${id}/`
  if (slug) {
    path = `${path}?slug=${slug}`
  }

  const building = (await apiFetch({ path })) as Building

  building.noViolations = building.tags
    ? (building.tags.find((t) => t.key === 'num_open_violations') || { count: 0 }).count
    : 0

  building.noEvictions = building.tags
    ? (building.tags.find((t) => t.key === 'num_evictions') || { count: 0 }).count
    : 0

  building.noLitigations = building.tags
    ? (building.tags.find((t) => t.key === 'num_pending_litigations') || { count: 0 }).count
    : 0

  building.noBedBugViolations = building.tags
    ? (building.tags.find((t) => t.key === 'num_bedbug_infestations_1y') || { count: 0 }).count
    : 0

  return building
}

export async function getBuildingOpenData(
  buildingId: string,
  collection: string,
  city = DEFAULT_CITY
): Promise<BuildingOpendataDetails> {
  return await apiFetch({
    path: `/${city}/buildings/${buildingId}/${collection}/`,
  })
}

export async function getBuildingReviews(buildingId: string, token: string): Promise<Review[]> {
  const requestData = {
    path: `management/buildings/${buildingId}/reviews/`,
    authToken: token,
  }
  return (await apiFetch(requestData)).results
}

export async function getBuildingRatingTypes(city: string = DEFAULT_CITY): Promise<Rating[]> {
  return (await apiFetch({ path: `/buildings/rating_types/?city=${city}` })).results as Rating[]
}

export async function getBuildingContacts(
  id: string,
  city: string = DEFAULT_CITY
): Promise<Contact[]> {
  const data = await apiFetch({ path: `/${city}/buildings/${id}/contacts/` })
  if (data && data.results) {
    return data.results as Contact[]
  }
  return []
}

export async function searchContacts(query = '', city: string = DEFAULT_CITY) {
  const data = await apiFetch({ path: `/${city}/contacts/?name=${query}`, noAuthorization: true })
  return data
}

export async function retrieveContact(id = '', city: string = DEFAULT_CITY) {
  const data = await apiFetch({ path: `/${city}/contacts/${id}/`, noAuthorization: true })
  return data
}

export async function getContactRatings(id = ''): Promise<AggRating[]> {
  const data = await apiFetch({ path: `/buildings/contacts/${id}/ratings/` , noAuthorization: true })
  return data
}

export async function requestReport(data) {
  const result = await apiFetch({
    path: `/management/report_request/`,
    method: 'post',
    data: snakecaseKeys(data),
    noAuthorization: true
  })
  return result
}

export function getResponsePercentages(
  responses: BuildingAggResponse | ContactAggResponse
): BuildingAggResponse {
  const { numReviews } = responses
  return {
    ...responses,
    recommendPerc:
      numReviews > 0 ? Math.ceil((responses.numRecommendBuilding / numReviews) * 100) : null,
    approvePerc: numReviews > 0 ? Math.ceil((responses.numApproveOwner / numReviews) * 100) : null,
    covidFlexPerc:
      responses.numCovidReviews > 0
        ? Math.ceil((responses.numCovidFlexibleRent / responses.numCovidReviews) * 100)
        : null,
    recDepositPerc:
      responses.numReceivedDeposit > 0
        ? Math.ceil((responses.numReceivedDeposit / responses.numReceivedDepositReviews) * 100)
        : null,
    rentStabilized: responses.numRentStabilizedReviews > 0 ? responses.numRentStabilized > 0 : null,
    hadCovidFlexibleRent: responses.numCovidReviews > 0 ? responses.numCovidFlexibleRent > 0 : null,
    hadCovidSanitation: responses.numCovidReviews > 0 ? responses.numCovidSanitation > 0 : null,
    rentIncrease: null,
  }
}

export async function getBuildingResponses(
  productId: string,
  authToken: string
): Promise<BuildingAggResponse> {
  const data = (await apiFetch({
    path: `/buildings/${productId}/responses/`,
    authToken,
  })) as BuildingAggResponse

  return getResponsePercentages(data) as BuildingAggResponse
}

export async function getBuildingOverview(
  productId: string,
  authToken: string | null = null
): Promise<BuildingOverview> {
  const overview = (await apiFetch({
    path: `/buildings/${productId}/`,
    authToken,
  })) as BuildingOverview
  const aggRatings = (await apiFetch({
    path: `/buildings/${productId}/ratings/`,
    authToken,
  })) as AggRating[]
  overview.aggRatings = aggRatings
  return overview
}

export async function getProductBuilding(
  buildingId: string,
  authToken: string | null = null
): Promise<ManagedCityBuilding> {
  const requestData = {
    path: `/management/buildings/${buildingId}/`,
  }
  if (authToken) {
    // @ts-expect-error
    requestData.authToken = authToken
  }
  return await apiFetch(requestData)
}

export async function createComment(
  reviewId: string,
  comment: string,
  token: string
): Promise<OwnerComment> {
  const requestData: APIFetchArgs = {
    path: 'comments/',
    method: 'POST',
    data: {
      review_id: reviewId,
      comment: comment,
    },
    authToken: token,
  }
  return await apiFetch(requestData)
}

export async function updateComment(
  commentId: string,
  updatedComment: string,
  token: string
): Promise<OwnerComment> {
  const requestData: APIFetchArgs = {
    path: `comments/${commentId}/`,
    method: 'PUT',
    data: {
      comment: updatedComment,
    },
    authToken: token,
  }
  return await apiFetch(requestData)
}

export async function deleteComment(commentId: string, token: string): Promise<OwnerComment> {
  const requestData: APIFetchArgs = {
    path: `comments/${commentId}/`,
    method: 'DELETE',
    authToken: token,
  }
  return await apiFetch(requestData)
}

export async function setBuildingImages(
  buildingId: string,
  images: string[],
  authToken: string
): Promise<ManagedCityBuilding> {
  let path = `/management/buildings/${buildingId}/set_images/`
  return (await apiFetch({
    path,
    authToken,
    method: 'put',
    data: { images },
  })) as ManagedCityBuilding
}

export async function setBuildingDescription(
  buildingId: string,
  description: string,
  authToken: string
): Promise<ManagedCityBuilding> {
  let path = `/management/buildings/${buildingId}/set_description/`
  return (await apiFetch({
    path,
    authToken,
    method: 'put',
    data: { description },
  })) as ManagedCityBuilding
}

// export async function getLeases(buildingId: string, authToken: string) {
//   const leases = await apiFetch({
//     path: `/tenancies/leases/managed/`,
//     authToken,
//     data: {
//       building_id: buildingId
//     }
//   })
//   return leases.results
// }
