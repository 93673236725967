import { landingSlice } from "@redux/reducers/landing"
import { RequestForm } from "src/@types/manager"
import { Landing } from "src/data"
import { getCities, landingRequestFormSubmit } from "src/data/Landing"

export const fetchCities = () => async (dispatch) => {
  const cities = (await getCities()).results
  cities.push(
    {
      id: 'other',
      name: 'Somewhere Else',
      center: {
        latitude: 40.7128,
        longitude: -74.006,
      },
    }
  )
  dispatch(landingSlice.actions.setCities(cities))
}

export const submitLandingForm =(data: RequestForm) => async () => {
  await landingRequestFormSubmit(data)
}