import EventAnalytics from '@openigloo/analytics'
const SEGMENT_KEY = process.env.NEXT_PUBLIC_SEGMENT_KEY
const SEGMENT_APP = 'web-v2'

import AnalyticJS from 'analytics'
import segmentPlugin from '@analytics/segment'

type annotation = { [key: string]: string | number }

type EventAnalyticsArgs = {
  segmentKey: string
  segmentApp: string
  eventAnotations?: annotation
}

declare global {
  interface Window {
    ttq: any
    userAgentType: string
  }
}

const EventContext = {
  MANAGER_ADDRESS_SEARCH_BAR_TAP: 'manager_address_search_bar_tap',
  MANAGER_CREATE_LISTING: 'manager_create_listing',
  MANAGER_HOME_GET_STARTED: 'manager_home_get_started',
  MANAGER_HOME_AGENT: 'manager_home_agent',
  MANAGER_HOME_RENTER: 'manager_home_renter',
  MANAGER_ONBOARDING_ADD_BUILDING: 'manager_onboarding_add_building',
  MANAGER_ONBOARDING_REMOVE_BUILDING: 'manager_onboarding_remove_building',
  MANAGER_ONBOARDING_SUBMIT_BUILDINGS: 'manager_onboarding_submit_buildings',
  MANAGER_ONBOARDING_BACK: 'manager_onboarding_back',
  MANAGER_ONBOARDING_CHECKOUT: 'manager_onboarding_checkout',
  MANAGER_ONBOARDING_PAYMENT: 'manager_onboarding_payment',
  MANAGER_ONBOARDING_FREE: 'manager_onboarding_free',
  MANAGER_LISTING_SUBMISSION: 'manager_listing_submission',
  MANAGER_NEW_UNIT_CREATE: 'manager_new_unit_create',
  MANAGER_SIDEBAR_LINK_CLICK: 'manager_sidebar_link_click',
  MANAGER_PAGE_CHANGE: 'manager_page_change',
  MANAGER_RESPOND_TO_REVIEWS: 'manager_respond_to_reviews',
  MANAGER_UPDATE_BUILDING_IMAGES: 'manager_update_building_images',
  MANAGER_DEACTIVATE_LISTING: 'manager_deactivate_listing',
  MANAGER_EDIT_LISTING: 'manager_edit_listing',
  MANAGER_DUPLICATE_LISTING: 'manager_duplicate_listing',
  MANAGER_ADD_OPEN_HOUSE_LISTING: 'manager_add_open_house_listing',
  MANAGER_APPROVE_APPLICATION: 'manager_approve_application',
  MANAGER_REJECT_APPLICATION: 'manager_reject_application',
  MANAGER_EDIT_RENT: 'manager_edit_rent',
  MANAGER_TRACK_INVOICE: 'manager_track_invoice',
  MANAGER_MESSAGE_TENANTS: 'manager_message_tenants',
  MANAGER_TABLE_PREFERRED_VIEW: 'manager_table_preferred_view',
  MANAGER_TABLE_SORT: 'manager_table_sort',
  MANAGER_TABLE_FILTER: 'manager_table_filter',
}

export class WebEventAnalytics extends EventAnalytics {
  constructor(args: EventAnalyticsArgs) {
    const instance = AnalyticJS({
      app: args.segmentApp,
      debug: true,
      plugins: [
        segmentPlugin({
          writeKey: args.segmentKey,
        }),
      ],
    })
    super(instance)
    this.annotate({
      appName: 'manager',
    })
  }

  page(url: string): void {
    let visitedURL
    if (!url || url === '') {
      visitedURL = location.pathname
    } else {
      visitedURL = url
    }
    this.instance.page({
      url: visitedURL,
      ...this.eventAnnotations,
    })
  }

  tiktokTrack(eventName: string, params?: { [key: string]: string }) {
    // ttq loaded via google tag manager
    if (window.ttq) {
      window.ttq.track(eventName, {
        ...params,
        content_id: window.userAgentType,
        contact_type: 'product',
      })
    }
  }

  trackHomeGettingStartedTap(section: string) {
    this.tap(EventContext.MANAGER_HOME_GET_STARTED, { section })
  }

  trackSearchAddressTap() {
    this.tap(EventContext.MANAGER_ADDRESS_SEARCH_BAR_TAP)
  }

  trackOnboardingAddBuildingTap(address: string) {
    this.tap(EventContext.MANAGER_ONBOARDING_ADD_BUILDING, { address })
  }

  trackOnboardingRemoveBuildingTap(address: string) {
    this.tap(EventContext.MANAGER_ONBOARDING_REMOVE_BUILDING, { address })
  }

  trackOnboardingSubmitBuildingsTap(count: number) {
    this.tap(EventContext.MANAGER_ONBOARDING_SUBMIT_BUILDINGS, { count })
  }

  trackOnboardingBackTap() {
    this.tap(EventContext.MANAGER_ONBOARDING_BACK)
  }

  trackOnboardingCheckoutTap() {
    this.tap(EventContext.MANAGER_ONBOARDING_CHECKOUT)
  }

  trackOnboardingPayment(success: boolean, amount: number) {
    this.track(EventContext.MANAGER_ONBOARDING_PAYMENT, { success, amount })
  }

  trackOnboardingFreeSubscription(count: number, onboarding: boolean = true) {
    this.track(EventContext.MANAGER_ONBOARDING_FREE, { count, onboarding })
  }

  trackCreateListing(listingId: string) {
    this.tap(EventContext.MANAGER_CREATE_LISTING, {listingId})
  }

  trackAgent() {
    this.tap(EventContext.MANAGER_HOME_AGENT)
  }

  trackRenter() {
    this.tap(EventContext.MANAGER_HOME_RENTER)
  }

  trackListingSubmission(email: string, listingId: string) {
    this.track(EventContext.MANAGER_LISTING_SUBMISSION, { email, listingId })
  }

  trackNewUnitCreation(email: string, unitId: string) {
    this.track(EventContext.MANAGER_NEW_UNIT_CREATE, { email, unitId })
  }

  trackNavigationViaSideBar(pageName: string) {
    this.track(EventContext.MANAGER_SIDEBAR_LINK_CLICK, { pageName })
  }

  trackPageChange(fromUrl: string, toUrl: string) {
    this.track(EventContext.MANAGER_PAGE_CHANGE, {from: fromUrl, to: toUrl})
  }

  trackBuildingImagesUpdate(building: string) {
    this.tap(EventContext.MANAGER_UPDATE_BUILDING_IMAGES, {building})
  }

  trackRespondToReview(reviewId: string, comment: string) {
    this.tap(EventContext.MANAGER_RESPOND_TO_REVIEWS, {reviewId, comment})
  }

  trackEditListing(listingId: string, changedField: string) {
    this.tap(EventContext.MANAGER_EDIT_LISTING, {listingId, changedField})
  }
  trackDeactivateListing(listingId: string) {
    this.tap(EventContext.MANAGER_DEACTIVATE_LISTING, {listingId})
  }
  trackDuplicateListing(listingId: string) {
    this.tap(EventContext.MANAGER_DUPLICATE_LISTING, {listingId})
  }
  trackOpenHouses(listingId: string) {
    this.tap(EventContext.MANAGER_ADD_OPEN_HOUSE_LISTING, {listingId})
  }
  trackApplicationApprove(applicationId: string) {
    this.tap(EventContext.MANAGER_APPROVE_APPLICATION, {applicationId})
  }
  trackApplicationReject(applicationId: string) {
    this.tap(EventContext.MANAGER_REJECT_APPLICATION, {applicationId})
  }
  trackInvoiceTrack(invoice: any) {
    this.track(EventContext.MANAGER_TRACK_INVOICE, {invoice})
  }
  trackEditRent(entityId: string, originalRent: number, updatedRent: number) {
    this.track(EventContext.MANAGER_EDIT_RENT, { entityId, originalRent, updatedRent})
  }
  trackMessageAll(unitId: string) {
    this.tap(EventContext.MANAGER_MESSAGE_TENANTS, {unitId})
  }
  trackPreferredView(view: string) {
    this.track(EventContext.MANAGER_TABLE_PREFERRED_VIEW, { view })
  }
  trackTableSort(table: string, sort: string) {
    this.track(EventContext.MANAGER_TABLE_SORT, { table, sort })
  }
  trackTableFilter(table: string, filter: any) {
    this.track(EventContext.MANAGER_TABLE_FILTER, { table, filter })
  }
}

export const segment = new WebEventAnalytics({ segmentApp: SEGMENT_APP, segmentKey: SEGMENT_KEY })
