import { apiFetch, apiFetchPaginated } from './Api'
import { Building } from '@openigloo/types'
import {
  BankAccount,
  Lease,
  ManagedCityBuilding,
  PaidSummary,
  PaymentStatus,
  StripeAccount,
  StripeSummary,
  PaymentHistory
} from 'src/@types/manager'

const USERS_COLLECTION_PATH = 'accounts'
// const CURRENT_CITY = 'nyc'

// export async function getManagedBuilding(id: string): Promise<Building> {
//   const requestData = {
//     path: `buildings/${id}/`,
//   }
//   const productBuilding: ProductBuilding = await apiFetch(requestData)
//   const managedBuilding = await apiFetch({
//     path: `${productBuilding.city}/buildings/${productBuilding.cityBuildingId}/`,
//   })
//   return managedBuilding
// }

export async function addManagedBuilding(id: string): Promise<ManagedCityBuilding> {
  try {
    const productBuilding: ManagedCityBuilding = await apiFetch({
      path: `/management/buildings/`,
      method: 'POST',
      data: {
        building_id: id,
      },
    })
    return productBuilding
  } catch (e) {
    console.error(`Building was not added, buildingId: ${id}, ${e}`)
  }
}

export async function removeManagedBuilding(id: string): Promise<Building> {
  const requestData = {
    path: `/management/buildings/${id}/`,
    method: 'delete',
  }
  try {
    // @ts-expect-error
    return await apiFetch(requestData)
  } catch (e) {
    console.error(e)
  }
}

export async function createCheckoutPageUrl(token: string) {
  const requestData = {
    path: `/${USERS_COLLECTION_PATH}/managed_buildings/checkout_page/`,
    method: 'post',
    authToken: token,
    data: {
      success_url: window.location.origin + '/onboarding/success',
      fail_url: window.location.origin + '/onboarding/fail',
    },
  }
  // @ts-expect-error
  return await apiFetch(requestData)
}

export async function postSuccessfulCheckout(sessionId: string, authToken: string): Promise<any> {
  return await apiFetch({
    path: `/${USERS_COLLECTION_PATH}/managed_buildings/complete_subscription_checkout/`,
    method: 'POST',
    data: {
      checkout_session_id: sessionId,
    },
    authToken,
  })
}

export async function getSubscriptionPrice(
  authToken: string,
  active: boolean = true
): Promise<any> {
  return await apiFetch({
    path: `/${USERS_COLLECTION_PATH}/managed_buildings/subscription_price/`,
    method: 'GET',
    params: {
      active,
    },
    authToken,
  })
}

export async function updateSubscription(authToken: string): Promise<any> {
  try {
    return await apiFetch({
      path: `/${USERS_COLLECTION_PATH}/managed_buildings/update_subscription/`,
      method: 'POST',
      authToken,
    })
  } catch (e) {
    console.error('Update susbcription failed' + e)
  }
}

// export async function addFreeSubscription(authToken: string): Promise<any> {
//   try {
//     return await apiFetch({
//       path: `/${USERS_COLLECTION_PATH}/managed_buildings/complete_free_subscription/`,
//       method: 'POST',
//       authToken,
//     })
//   } catch (e) {
//     console.error('Free susbcription failed ' + e)
//   }
// }

export async function createStripeAccount(data, authToken: string): Promise<StripeAccount> {
  return await apiFetch({
    path: `/management/stripe_connect_account/`,
    authToken,
    method: 'POST',
    data,
  })
}

export async function editAccount(accountId: string, authToken: string): Promise<any> {
  return await apiFetch({
    path: `/management/stripe_connect_account_link/?account_id=${accountId}`,
    authToken,
  })
}

export async function getStripeAccounts(
  authToken: string
): Promise<{ count: number; next: string; previous: string; results: StripeAccount[] }> {
  return await apiFetch({
    path: `/management/stripe_connect_accounts/`,
    authToken,
  })
}

export async function getStripeBankAccountSession(
  accountId: string,
  authToken: string
): Promise<{ clientSecret: string }> {
  return await apiFetch({
    path: `/management/stripe_connect_bank_account_session/`,
    method: 'POST',
    data: {
      account_id: accountId,
    },
    authToken,
  })
}

export async function addStripeBankAccount(
  bankToken: string,
  accountId: string,
  authToken: string
): Promise<{
  id: string
  name: string
  pendingRequirements: boolean
  bankAccounts: BankAccount[]
}> {
  return await apiFetch({
    path: `/management/stripe_connect_bank_account/?bank_token=${bankToken}`,
    authToken,
    data: {
      account_id: accountId,
    },
    method: 'POST',
  })
}

export async function associateStripeAccountToLease(
  accountId: string,
  unitId: string,
  authToken: string
): Promise<{ count: number; next: string; previous: string; results: Lease[] }> {
  return await apiFetch({
    path: `management/units/${unitId}/update_connect_account/?account_id=${accountId}`,
    method: 'POST',
    authToken,
  })
}

export async function getPayoutHistory(accountId: string, authToken: string): Promise<any> {
  return await apiFetch({
    path: `management/payout_history/?account_id=${accountId}`,
    authToken,
  })
}

export async function getMonthPayoutTracker(month: string, authToken: string): Promise<any> {
  const payout = await apiFetch({
    path: `tenancies/rent_payments/managed/?month=${month}`,
    authToken,
  })
  return payout.results
}

export async function getPaymentStatus(
  rentPaymentId: string,
  authToken: string
): Promise<PaymentStatus[]> {
  return await apiFetch({
    path: `/tenancies/rent_payments/${rentPaymentId}/status/`,
    authToken,
  })
}

export async function getMonthPaymentSummary(
  month: string,
  authToken: string
): Promise<PaidSummary> {
  return await apiFetch({
    path: `/management/units/monthly_rent_payments_summary/?month=${month}`,
    authToken,
  })
}

export async function getMonthPaymentList(
  month: string,
  authToken: string
): Promise<{count: number, next: string, previous: string, results: PaymentHistory[]}> {
  return await apiFetch({
    path: `/management/units/monthly_rent_payments/?month=${month}`,
    authToken,
  })
}

export async function getPayoutSummary(
  month: string,
  accountId: string,
  authToken: string
): Promise< StripeSummary[]> {
  return await apiFetchPaginated({
    path: `/management/payouts/?account_id=${accountId}&month=${month}`,
    data: {
account_id: accountId,
month
    },
    authToken,
  })
}
