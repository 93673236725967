import { buildingSlice } from '@redux/reducers/building'
import { userSlice } from '@redux/reducers/user'
import { AppState } from '@redux/store'
import { createAsyncThunk, current } from '@reduxjs/toolkit'
import { flatten, map } from 'lodash'
import { Dispatch } from 'redux'
import { DraftUnit, Lease, ManagedCityBuilding, ManagedCityUnit } from 'src/@types/manager'
import { Buildings, Units, UserProfile } from 'src/data'
import ACTIONS from '@redux/constants'

const { BUILDING } = ACTIONS

export const draftNewUnit = (unit: ManagedCityUnit = null): DraftUnit => {
  return {
    id: '',
    city: '',
    cityUnitId: '',
    isSaved: false,
    isManaged: false,
    isInterested: false,
    numInterests: 0,
    cityUnit: {
      ...(unit
        ? { ...unit.cityUnit, id: '', unitNumber: '', productUnit: null, listings: [] }
        : {
            id: '',
            unitNumber: '',
            noBedrooms: null,
            noBathrooms: null,
            noHalfBathrooms: null,
            noRooms: null,
            area: null,
            isPetsAllowed: false,
            hasPrivateOutdoorSpace: false,
            hasDishwasher: false,
            hasWasherDryer: false,
            images: [],
            floorPlan: '',
            activeListing: null,
            fullAddress: '',
            addressSlug: '',
            tourVideo: '',
            description: '',
            listings: [],
            building: null,
            productUnit: null,
            amenities: [],
            openHouseTimes: null,
          }),
    },
    leases: [],
    buildingId: '',
  }
}

export const setCurrentBuildingObj =
  (buildingId: string | null) => async (dispatch: Dispatch, getState: () => AppState) => {
    const {
      currentUser: { userManagedBuildings },
    } = getState()
    if (buildingId === null) {
      dispatch(buildingSlice.actions.setCurrentBuilding(null))
    } else {
      let building
      if (userManagedBuildings) {
        building = userManagedBuildings.find((building) => building.id === buildingId)
      }
      if (building) {
        dispatch(buildingSlice.actions.setCurrentBuilding(building))
      } else {
        const productBuilding = await Buildings.getProductBuilding(buildingId)
        if (productBuilding) {
          dispatch(buildingSlice.actions.setCurrentBuilding(productBuilding))
        }
      }
    }
    const overview = await Buildings.getBuildingOverview(buildingId)
    dispatch(buildingSlice.actions.setOverview(overview))
  }

export const setCurrentBuildingReview = createAsyncThunk(
  BUILDING.GET_REVIEWS,
  async ({ buildingId = '', token = ''}: {buildingId: string; token: string }) => {
    return await Buildings.getBuildingReviews(buildingId, token)
  }
)

export const setManagedUnitsForCurrentBuilding = createAsyncThunk(
  BUILDING.GET_UNITS,
  async ({ buildingId, token }: { buildingId: string; token: string }) => {
    const res = await Units.getBuildingUnits(buildingId, token)
    return res
  }
)

export const setPaginatedManagedUnitsForCurrentBuilding = createAsyncThunk(
  BUILDING.GET_PAGINATED_UNITS,
  async ({
    buildingId,
    token,
    page = 1,
    params,
  }: {
    buildingId: string
    token: string
    page?: number
    params?: {}
  }) => {
    const res = await Units.getPaginatedBuildingUnits(token, {
      buildingId,
      page,
      ...params,
    })
    return { ...res, page }
  }
)

export const resetPaginatedManagedUnits = () => (dispatch) => {
  dispatch(buildingSlice.actions.resetPaginatedManagedUnits())
}

export const cleanupPaginatedManagedUnits = (pages: string[]) => (dispatch) => {
  dispatch(buildingSlice.actions.cleanupPaginatedManagedUnits(pages))
}

const CollectionMap = ['open_violations', 'bedbug_infestations_1y', 'litigations', 'evictions']

export const setCurrentBuildingOpendata = createAsyncThunk<any, { buildingId: string }>(
  BUILDING.GET_OPENDATA,
  async ({ buildingId }) => {
    const openData = {}
    await Promise.all(
      CollectionMap.map(async (collection: string) => {
        const data = await Buildings.getBuildingOpenData(buildingId, collection)
        openData[collection] = data
      })
    )
    return openData
  }
)


export const setCurrentUnit =
  (unitId: string | null) => async (dispatch: Dispatch, getState: () => AppState) => {
    const {
      currentUser: { userManagedUnits, token },
    } = getState()
    if (!unitId) {
      dispatch(buildingSlice.actions.setCurrentUnit(null))
    }
    if (unitId) {
      if (userManagedUnits) {
        const unit = userManagedUnits.find((unit) => unit.id === unitId)
        if (unit) {
          dispatch(buildingSlice.actions.setCurrentUnit(unit || null))
        } else {
          const fetchedUnit = await Units.getUnit(unitId, token)
          dispatch(buildingSlice.actions.setCurrentUnit(fetchedUnit))
        }
      } else {
        const fetchedUnit = await Units.getUnit(unitId, token)
        dispatch(buildingSlice.actions.setCurrentUnit(fetchedUnit))
      }
    }
  }

export const updateCurrentBuilding = (building: ManagedCityBuilding) => (dispatch: Dispatch) => {
  dispatch(buildingSlice.actions.setCurrentBuilding(building))
}

export const saveCurrentBuildingImages =
  (id: string, images: string[], token: string) => async (dispatch: Dispatch, getState) => {
    const {
      currentUser: { userManagedBuildings },
    } = getState()
    const bld = await Buildings.setBuildingImages(id, images, token)
    dispatch(buildingSlice.actions.setCurrentBuilding(bld))
    if (token) {
      const filtered = userManagedBuildings.map((blding) => (blding.id == bld.id ? bld : blding))
      dispatch(userSlice.actions.setUserManagedBuilding(filtered))
    }
  }

export const saveCurrentBuildingDescription =
  (id: string, description: string, token: string) => async (dispatch: Dispatch, getState) => {
    const {
      currentUser: { userManagedBuildings },
    } = getState()
    const bld = await Buildings.setBuildingDescription(id, description, token)
    dispatch(buildingSlice.actions.setCurrentBuilding(bld))
    if (token) {
      const filtered = userManagedBuildings.map((blding) => (blding.id == bld.id ? bld : blding))
      dispatch(userSlice.actions.setUserManagedBuilding(filtered))
    }
  }

export const updateCurrentUnit = (unit: ManagedCityUnit) => (dispatch: Dispatch, getState) => {
  const {
    currentBuilding: { managedUnits },
    currentUser: { userManagedUnits },
  } = getState()
  dispatch(buildingSlice.actions.setCurrentUnit(unit))
  const units = userManagedUnits?.map((umu) => (umu.id == unit.id ? unit : umu))
  dispatch(userSlice.actions.setUserManagedUnits(units))
  const buildingUnits = managedUnits?.map((mU) => (mU.id == unit.id ? unit : mU))
  dispatch(buildingSlice.actions.setManagedUnits(buildingUnits))
}

export const saveCurrentUnitUpdates =
  (unitId: string, unit: ManagedCityUnit | DraftUnit, token: string) =>
  async (dispatch: Dispatch, getState) => {
    const {
      currentUser: { userManagedUnits },
    } = getState()
    const unitData = await Units.updateUnit(unitId, unit, token)
    dispatch(buildingSlice.actions.setCurrentUnit(unitData))

    const units = userManagedUnits?.map((unit) => (unit.id == unitData.id ? unitData : unit))
    dispatch(userSlice.actions.setUserManagedUnits(units))
  }

export const createCurrentBuildingReview =
  (reviewId: string, comment: string, token: string) => async (dispatch: Dispatch) => {
    await Buildings.createComment(reviewId, comment, token)
    // if (token) {
    //   // @ts-ignore
    //   dispatch(getAllUserManagedBuildings(token))
    // }
  }

export const editCurrentBuildingReview =
  (commentId: string, comment: string, token: string) => async (dispatch: Dispatch) => {
    await Buildings.updateComment(commentId, comment, token)
    // if (token) {
    //   // @ts-ignore
    //   dispatch(getAllUserManagedBuildings(token))
    // }
  }

export const deleteCurrentBuildingReview =
  (commentId: string, token: string) => async (dispatch: Dispatch) => {
    await Buildings.deleteComment(commentId, token)
    // if (token) {
    //   // @ts-ignore
    //   dispatch(getAllUserManagedBuildings(token))
    // }
  }

export const fetchCurrentUnitApplications =
  (unitId: string, token: string) => async (dispatch: Dispatch) => {
    const applications = await Units.getUnitApplications(unitId, token)
    // @ts-ignore
    dispatch(buildingSlice.actions.setCurrentUnitApplications(applications.results || []))
  }

export const setCityAmenities = (city: string) => async (dispatch: Dispatch) => {
  const data = await Units.fetchCityAmenities(city)
  dispatch(buildingSlice.actions.setCityAmenities(data))
}
