import { City } from "src/@types/manager"
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type LandingState = {
  cities: City[] | null
}

const initialState: LandingState = {
  cities: [],
}

export const landingSlice = createSlice({
  name: 'currentLanding',
  initialState,
  reducers: {
    setCities(state, action: PayloadAction<any[]>) {
      state.cities = action.payload
    },
  }
})

export default landingSlice.reducer