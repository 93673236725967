import type { AppProps } from 'next/app'
import { wrapper } from '@redux/store'

import { useEffect } from 'react'

import { useRouter } from 'next/router'
import { AuthProvider, useAuth } from '../stores/authContext'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import '../styles/theme.css'
import '../styles/globals.sass' //IMPORTANT: this needs to be the last style file
import { segment } from 'src/lib/analytics'
import Script from 'next/script'
import { useDispatch } from 'react-redux'
import { fetchCities, isChatlioReady } from '@redux/actions'
import { useAppSelector } from '@redux/hooks'
import { landingSlice } from '@redux/reducers/landing'

NProgress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
})

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { cities } = useAppSelector((state) => state.currentLanding)
  const { token } = useAppSelector((state) => state.currentUser)
  const { isLoggedIn } = useAppSelector((state) => state.currentUser)
  const { logout } = useAuth()

  useEffect(() => {
    if(router.pathname !== '/' && router.pathname !== '/listings-quality-policy' && router.pathname !== '/faqs'){
      router.push('/')
    }
  },[router])

  useEffect(() => {
    // @ts-expect-error
    const chatlio = window._chatlio || []
    if (chatlio && chatlio.isShown && chatlio.isShown()) {
      document.addEventListener('chatlio.visibilityChange', handleChatConfig)
      return () => document.removeEventListener('chatlio.visibilityChange', handleChatConfig)
    }
    if(cities?.length === 0){
      dispatch(fetchCities())
    }
  }, [])

  const handleChatConfig = (event) => {
    // @ts-expect-error
    const chatlio = window._chatlio || []
    if (chatlio && event.target.id == 'chatlio-widget') {
      chatlio.configure({
        requireInfoSubmitBtn: 'Start',
        requireInfoGreeting: 'Enter your name and email to start chatting!',
      })
    }
  }

  useEffect(() => {
    let currentPath = ''
    const handleRouteChange = (url: string): void => {
      segment.page(url) //SPA page change
      segment.trackPageChange(currentPath, url)
      NProgress.done()
    }
    router.events.on('routeChangeStart', (url) => {
      currentPath = router.pathname
      NProgress.start()
    })
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', () => NProgress.start())
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <AuthProvider>
      <Script
        type="text/javascript"
        src="/chatlioScript.js"
        onLoad={() => dispatch(isChatlioReady(true))}
      />

      <Component {...pageProps} />
    </AuthProvider>
  )
}

export default wrapper.withRedux(App)
