// From https://github.com/kirill-konshin/next-redux-wrapper/blob/master/packages/demo-redux-toolkit/store.tsx
import { createWrapper } from 'next-redux-wrapper'
import { configureStore } from '@reduxjs/toolkit'
import {
  buildingReducer,
  userReducer,
  loadingReducer,
  landingReducer,
  contactReducer,
} from './reducers'

export const Store = configureStore({
  reducer: {
    currentUser: userReducer,
    currentBuilding: buildingReducer,
    currentContact: contactReducer,
    currentLoading: loadingReducer,
    currentLanding: landingReducer,
  },
})
export function makeStore() {
  return Store
}

export type AppStore = typeof Store
export type AppState = ReturnType<AppStore['getState']>
export type AppDispatch = typeof Store.dispatch
export const wrapper = createWrapper<AppStore>(makeStore)
