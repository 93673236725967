import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { Contact, ContactOverview } from '@openigloo/types'
import { ContactReport, ContactState } from 'src/@types/contact'
import { Review } from 'src/@types/manager'

const initialState: ContactState = {
  contact: null,
  reviews: [],
  overview: null,
  report: null,
}

export const contactSlice = createSlice({
  name: 'currentContact',
  initialState,
  reducers: {
    setContact(state, action: PayloadAction<Contact>) {
      state.contact = action.payload
    },
    setReviews(state, action: PayloadAction<Review[]>) {
      state.reviews = action.payload
    },
    setOverview(state, action: PayloadAction<ContactOverview>) {
      state.overview = action.payload
    },
    setReport(state, action: PayloadAction<ContactReport>) {
      state.report = action.payload
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.currentContact,
      }
    },
  },
})

export default contactSlice.reducer
