import {
  Application,
  CreateListing,
  DraftUnit,
  Lease,
  ManagedCityListing,
  ManagedCityUnit,
  OpenHouseTime,
} from '../@types/manager'
import { apiFetch, apiFetchPaginated } from './Api'
import snakecaseKeys from 'snakecase-keys'

const CURRENT_CITY = 'nyc'
const USERS_COLLECTION_PATH = 'accounts'
const APPLICATIONS_PATH = 'applications'

export const createUnit = async (
  buildingId: string,
  completeUnitData: DraftUnit,
  authToken: string
): Promise<ManagedCityUnit> => {
  const data = {
    unitNumber: completeUnitData.cityUnit.unitNumber,
    noBathrooms: completeUnitData.cityUnit.noBathrooms,
    noHalfBathrooms: completeUnitData.cityUnit.noHalfBathrooms || 0,
    noBedrooms: completeUnitData.cityUnit.noBedrooms,
    noRooms: completeUnitData.cityUnit.noRooms,
    area: completeUnitData.cityUnit.area,
    images: completeUnitData.cityUnit.images,
    description: completeUnitData.cityUnit.description,
    tourVideo: completeUnitData.cityUnit.tourVideo,
    floorPlan: completeUnitData.cityUnit.floorPlan,
  }
  return await apiFetch({
    path: `/management/buildings/${buildingId}/create_unit/`,
    method: 'PUT',
    data: snakecaseKeys(data),
    authToken,
  })
}

export async function getBuildingUnits(
  buildingId: string,
  authToken: string
): Promise<ManagedCityUnit[]> {
  return await apiFetchPaginated({
    path: `/management/units/?building_id=${buildingId || null}`,
    authToken,
  })
}

export async function getPaginatedBuildingUnits(
  authToken: string,
  params: any
): Promise<{
  count: number
  next: string | null
  previous: string | null
  results: ManagedCityUnit[]
}> {
  const snakecaseParams = snakecaseKeys(params)
  const paramsPath = Object.keys(snakecaseParams)
    .reduce((preV, curV) => `${preV}${curV}=${snakecaseParams[curV]}&`, '')
    .slice(0, -1)
  return await apiFetch({
    path: `/management/units/?${paramsPath}`,
    authToken,
  })
}

export const getUnit = async (unitId: string, authToken: string): Promise<ManagedCityUnit> => {
  return await apiFetch({
    path: `/management/units/${unitId}/`,
    authToken,
  })
}

export async function updateUnit(
  unitId: string,
  unit: ManagedCityUnit | DraftUnit,
  authToken: string
): Promise<ManagedCityUnit> {
  let path = `/management/units/${unitId}/`
  return (await apiFetch({
    path,
    authToken,
    method: 'PUT',
    data: snakecaseKeys({
      noBathrooms: unit.cityUnit.noBathrooms,
      noHalfBathrooms: unit.cityUnit.noHalfBathrooms,
      noBedrooms: unit.cityUnit.noBedrooms,
      noRooms: unit.cityUnit.noRooms,
      area: unit.cityUnit.area,
      images: unit.cityUnit.images,
      description: unit.cityUnit.description,
      tourVideo: unit.cityUnit.tourVideo,
      floorPlan: unit.cityUnit.floorPlan,
      openHouseTimes: unit.cityUnit.openHouseTimes,
    }),
  })) as ManagedCityUnit
}

export const addUnitAmenity = async (
  unitId: string,
  amenityId: string,
  authToken: string
): Promise<void> => {
  return await apiFetch({
    path: `/management/units/${unitId}/amenities/`,
    method: 'PUT',
    data: {
      amenity_id: amenityId,
    },
    authToken,
  })
}

export const removeUnitAmenity = async (
  unitId: string,
  amenityId: string,
  authToken: string
): Promise<void> => {
  return await apiFetch({
    path: `/management/units/${unitId}/amenities/`,
    method: 'DELETE',
    data: {
      amenity_id: amenityId,
    },
    authToken,
  })
}

export const createListing = async (unitId: string, data: CreateListing, authToken: string) => {
  return await apiFetch({
    path: `/management/units/${unitId}/create_listing/`,
    method: 'PUT',
    data: snakecaseKeys(data),
    authToken,
  })
}

export const getUnitListings = async (
  unitId: string,
  authToken: string
): Promise<ManagedCityListing[]> => {
  const list = await apiFetch({
    path: `/management/units/${unitId}/listings/`,
    authToken,
  })
  return list.results
}
// Not Updated
// export const updateListing = async (
//   unitId: string,
//   listingId: string,
//   data: any,
//   authToken: string
// ) => {
//   return await apiFetch({
//     path: `/${CURRENT_CITY}/units/${unitId}/listings/`,
//     method: 'PUT',
//     data: snakecaseKeys({ ...data, listing_id: listingId }),
//     authToken,
//   })
// }

// Not Updated
// export const activateListing = async (unitId: string, listingId: string, authToken: string) => {
//   return await apiFetch({
//     path: `/${CURRENT_CITY}/units/${unitId}/activate/`,
//     method: 'PUT',
//     data: { listing_id: listingId },
//     authToken,
//   })
// }

export const deactivateListing = async (unitId: string, authToken: string) => {
  return await apiFetch({
    path: `/management/units/${unitId}/deactivate_listing/`,
    method: 'PUT',
    // data: { listing_id: listingId },
    authToken,
  })
}

export async function getApplication(
  applicationId: string,
  authToken: string
): Promise<Application> {
  return await apiFetch({
    path: `/${APPLICATIONS_PATH}/${applicationId}/`,
    authToken,
  })
}

export async function getUnitApplications(unitId: string, authToken: string): Promise<any> {
  return await apiFetch({
    path: `/${APPLICATIONS_PATH}/?unit_id=${unitId}`,
    authToken,
  })
}

export async function rejectApplication(applicationId: string, authToken: string): Promise<any> {
  return await apiFetch({
    path: `/${APPLICATIONS_PATH}/${applicationId}/reject/`,
    method: 'PUT',
    authToken,
  })
}

export async function acceptApplication(
  applicationId: string,
  data: any,
  authToken: string
): Promise<Application> {
  return await apiFetch({
    path: `/${APPLICATIONS_PATH}/${applicationId}/accept/`,
    method: 'PUT',
    data,
    authToken,
  })
}

export async function getLeasePaymentHistory(leaseId: string) {
  let path = `/tenancies/rent_payments/managed/?lease_id=${leaseId}`
  return (await apiFetch({ path })).results
}

export async function editRentPayment(paymentId: string, data) {
  const path = `/tenancies/rent_payments/${paymentId}/update_rent/`
  return await apiFetch({
    path,
    method: 'PUT',
    data,
  })
}

export async function uploadLeaseDocument(
  unitId: string,
  document: File,
  authToken: string
): Promise<{ count: number; next: string; previous: string; results: Lease[] }> {
  const path = `/management/units/${unitId}/upload_lease_document/`
  const data = new FormData()
  data.append('lease_document', document)
  return await apiFetch({
    path,
    method: 'POST',
    data,
    authToken,
  })
}

export async function removeLeaseDocument(unitId: string, authToken: string) {
  const path = `/management/units/${unitId}/remove_lease_document/`
  return await apiFetch({
    path,
    method: 'DELETE',
    authToken,
  })
}

export async function fetchCityAmenities(city: string) {
  const path = `/buildings/amenities/?city=${city}`
  const amenitiesList = await apiFetch({
    path,
  })
  return amenitiesList.results
}

export async function saveSummary(applicationId, notes, authToken: string) {
  const path = `/applications/${applicationId}/add_notes/`
  const data = { notes }
  return await apiFetch({
    path,
    method: 'PUT',
    authToken,
    data,
  })
}

export async function getUnitLeases(unitId: string, authToken: string): Promise<any> {
  return await apiFetch({
    path: `/management/units/${unitId}/leases/`,
    authToken,
  })
}

export async function getUnitLeads(unitId: string, authToken: string): Promise<any> {
  return await apiFetchPaginated({
    path: `/management/units/${unitId}/interests/`,
    authToken,
  })
}

export async function getOpenHouseTimes(
  unitId: string,
  authToken: string
): Promise<OpenHouseTime[]> {
  return await apiFetchPaginated({
    path: `/management/units/${unitId}/open_house_times/`,
    authToken,
  })
}

export async function getOpenHouseRegistrations(
  unitId: string,
  openHouseId: string,
  authToken: string
): Promise<any> {
  return await apiFetchPaginated({
    path: `/management/units/${unitId}/open_house_registrations/`,
    authToken,
    data: {
      open_house_time_id: openHouseId,
    },
  })
}

export async function addOpenHouseTimes(
  unitId: string,
  hours: string[],
  authToken: string
): Promise<OpenHouseTime> {
  const path = `/management/units/${unitId}/open_house_times/`
  return await apiFetch({
    path,
    method: 'POST',
    authToken,
    data: {
      hours,
    },
  })
}

export async function deleteOpenHouseTimes(
  unitId: string,
  openHouseId: string,
  authToken: string
): Promise<OpenHouseTime> {
  const path = `/management/units/${unitId}/open_house_times/`
  const data = { open_house_time_id: openHouseId }
  return await apiFetch({
    path,
    method: 'DELETE',
    authToken,
    data,
  })
}

export async function renewLease(
  unitId: string,
  data: { rent_change_multiplier: number; fee_change_multiplier: number; num_months: number },
  authToken: string
): Promise<OpenHouseTime> {
  const path = `/management/units/${unitId}/renew_lease/`
  return await apiFetch({
    path,
    method: 'POST',
    authToken,
    data,
  })
}
