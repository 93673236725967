import { apiFetch } from './Api'
import { City, RequestForm } from 'src/@types/manager' 

export async function getCities(): Promise<{ results: City[] }> {
  return await apiFetch({
    path: `/buildings/cities/`,
    noAuthorization: true
  })
}

export async function landingRequestFormSubmit(data: RequestForm): Promise<RequestForm> {
  return await apiFetch({
    path: `/management/intake_form/`,
    method: 'POST',
    data: data,
    noAuthorization: true
  })
}