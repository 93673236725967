import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { UserProfile as UserProfileType } from '@openigloo/types'
import { PaginationData } from 'src/data/UserProfile'
import {
  Application,
  InvoiceItem,
  Lease,
  ManagedCityBuilding,
  ManagedCityUnit,
  ManagementSummary,
  StripeAccount,
  TrackRentItem,
  UserPermissions,
} from 'src/@types/manager'
import {
  getUserManagedBuildings,
  getUserManagedUnits,
  getUserManagedListings,
  getUserManagedTenants,
  getUserManagedApplications,
  fetchStripeAccounts,
  fetchManagementSummary,
} from '@redux/actions'
import { UserProfile } from 'src/data'
import { flatten } from 'lodash'

export type UserState = {
  profile: UserProfileType | null
  token: string
  isLoggedIn: boolean
  userPermissions: UserPermissions
  userManagedBuildings: ManagedCityBuilding[]
  userManagedUnits: ManagedCityUnit[]
  userManagedTenants: ManagedCityUnit[]
  userManagedListings: ManagedCityUnit[]
  userManagedApplications: Application[]
  chatlioReady: boolean
  buildingsApiData: {
    count: number
    next: string
    previous: string
    currentPage: number
    pageTotal: number
    pageSize: number
  }
  unitsApiData: {
    count: number
    next: string
    previous: string
    currentPage: number
    pageTotal: number
    pageSize: number
  }
  applicationsApiData: {
    count: number
    next: string
    previous: string
    currentPage: number
    pageTotal: number
    pageSize: number
  }
  tenantsApiData: {
    count: number
    next: string
    previous: string
    currentPage: number
    pageTotal: number
    pageSize: number
  }
  listingsApiData: {
    count: number
    next: string
    previous: string
    currentPage: number
    pageTotal: number
    pageSize: number
  }
  stripeAccounts: StripeAccount[]
  managementSummary: ManagementSummary
  invoiceItems: TrackRentItem[]
}

export const initialState: UserState = {
  token: '',
  isLoggedIn: false,
  userPermissions: null,
  userManagedBuildings: [],
  userManagedUnits: [],
  userManagedTenants: [],
  userManagedListings: [],
  userManagedApplications: [],
  profile: null,
  chatlioReady: false,
  buildingsApiData: {
    count: 0,
    next: null,
    previous: null,
    currentPage: 1,
    pageTotal: 0,
    pageSize: 20,
  },
  unitsApiData: {
    count: 0,
    next: null,
    previous: null,
    currentPage: 1,
    pageTotal: 0,
    pageSize: 20,
  },
  applicationsApiData: {
    count: 0,
    next: null,
    previous: null,
    currentPage: 1,
    pageTotal: 0,
    pageSize: 20,
  },
  tenantsApiData: {
    count: 0,
    next: null,
    previous: null,
    currentPage: 1,
    pageTotal: 0,
    pageSize: 20,
  },
  listingsApiData: {
    count: 0,
    next: null,
    previous: null,
    currentPage: 1,
    pageTotal: 0,
    pageSize: 20,
  },
  stripeAccounts: [],
  managementSummary: null,
  invoiceItems: [],
}

export const userSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setUserProfile(state, action: PayloadAction<UserProfileType>) {
      state.profile = action.payload
    },
    setToken(state, action) {
      state.token = action.payload
    },
    setIsLoggedIn(state, action) {
      state.isLoggedIn = action.payload
    },
    setUserPermissions(state, action) {
      state.userPermissions = action.payload
    },
    setUserManagedBuilding(state, action: PayloadAction<ManagedCityBuilding[]>) {
      state.userManagedBuildings = action.payload
    },
    addAdditionalBuildings(state, action: PayloadAction<ManagedCityBuilding[]>) {
      state.userManagedBuildings = [...(state.userManagedBuildings || []), ...action.payload]
    },
    setBuildingsCurrentPage(state, action: PayloadAction<any>) {
      state.buildingsApiData.currentPage = action.payload
    },
    setApplicationsCurrentPage(state, action: PayloadAction<any>) {
      state.applicationsApiData.currentPage = action.payload
    },
    setListingsCurrentPage(state, action: PayloadAction<any>) {
      state.listingsApiData.currentPage = action.payload
    },
    setTenantsCurrentPage(state, action: PayloadAction<any>) {
      state.tenantsApiData.currentPage = action.payload
    },
    setUserManagedUnits(state, action: PayloadAction<ManagedCityUnit[]>) {
      state.userManagedUnits = action.payload
    },
    setUserManagedTenants(state, action: PayloadAction<any>) {
      state.userManagedTenants = action.payload
    },
    setUserManagedListings(state, action: PayloadAction<ManagedCityUnit[]>) {
      state.userManagedListings = action.payload
    },
    setUserManagedApplications(state, action: PayloadAction<any>) {
      state.userManagedApplications = action.payload
    },
    setIsChatlioReady(state, action: PayloadAction<boolean>) {
      state.chatlioReady = action.payload
    },
    setStripeAccounts(state, action: PayloadAction<StripeAccount[]>) {
      state.stripeAccounts = action.payload
    },
    setCurrentInvoice(state, action: PayloadAction<TrackRentItem[] | null>) {
      state.invoiceItems = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserManagedUnits.fulfilled, (state, action: any) => {
        return {
          ...state,
          userManagedUnits: action.payload.all
            ? action.payload.results
            : [...(state.userManagedUnits || []), ...(action.payload.results || [])],
          unitsApiData: {
            count: action.payload.count,
            next: action.payload.next,
            previous: action.payload.previous,
            currentPage: action.payload.page,
            pageTotal: Math.ceil(action.payload.count / 20),
            pageSize: 20,
          },
        }
      })
      .addCase(getUserManagedTenants.fulfilled, (state, action: any) => {
        return {
          ...state,
          tenantsApiData: {
            count: action.payload.count,
            next: action.payload.next,
            previous: action.payload.previous,
            currentPage: action.payload.page,
            pageTotal: Math.ceil(action.payload.count / 20),
            pageSize: 20,
          },
          userManagedTenants: [
            ...(state.userManagedTenants || []),
            ...(action.payload.results || []),
          ],
        }
      })
      .addCase(getUserManagedListings.fulfilled, (state, action: any) => {
        return {
          ...state,
          listingsApiData: {
            count: action.payload.count,
            next: action.payload.next,
            previous: action.payload.previous,
            currentPage: action.payload.page,
            pageTotal: Math.ceil(action.payload.count / 20),
            pageSize: 20,
          },
          userManagedListings: action.payload.all
            ? action.payload.results
            : [...(state.userManagedListings || []), ...(action.payload.results || [])],
        }
      })
      .addCase(getUserManagedBuildings.fulfilled, (state, action: any) => {
        return {
          ...state,
          buildingsApiData: {
            next: action.payload.next,
            previous: action.payload.previous,
            count: action.payload.count,
            currentPage: action.payload.page,
            pageTotal: Math.ceil(action.payload.count / 20),
            pageSize: 20,
          },
          userManagedBuildings: action.payload.all
            ? action.payload.results
            : [...(state.userManagedBuildings || []), ...(action.payload.results || [])],
        }
      })
      .addCase(getUserManagedApplications.fulfilled, (state, action: any) => {
        return {
          ...state,
          applicationsApiData: {
            next: action.payload.next,
            previous: action.payload.previous,
            count: action.payload.count,
            currentPage: action.payload.page,
            pageTotal: Math.ceil(action.payload.count / 20),
            pageSize: 20,
          },
          userManagedApplications: action.payload.all
            ? action.payload.results
            : [...(state.userManagedApplications || []), ...(action.payload.results || [])],
        }
      })
      .addCase(fetchStripeAccounts.fulfilled, (state, action: any) => {
        return {
          ...state,
          stripeAccounts: action.payload.results,
        }
      })
      .addCase(fetchManagementSummary.fulfilled, (state, action: any) => {
        return {
          ...state,
          managementSummary: action.payload,
        }
      })
    // [HYDRATE]: (state, action) => {
    //   return {
    //     ...state,
    //     ...action.payload.currentUser,
    //   }
    // },
  },
})

export default userSlice.reducer
