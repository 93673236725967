export default {
  BUILDING: {
    GET_REVIEWS: 'building/getBuildingReviews',
    GET_UNITS: 'building/getBuildingUnits',
    GET_PAGINATED_UNITS: 'building/getPaginatedBuildingUnits',
    GET_OPENDATA: 'building/getBuildingOpenData'
  },
  USER: {
    GET_BUILDINGS: 'user/getUserManagedBuildings',
    GET_UNITS: 'user/getUserManagedUnits',
    GET_LISTINGS: 'user/getUserManagedListings',
    GET_TENANTS: 'user/getUserManagedTenants',
    GET_APPLICATIONS: 'user/getUserManagedApplications',
    GET_STRIPE_ACCOUNTS: 'user/getStripeAccounts',
    GET_MANAGEMENT_SUMMARY: 'user/getManagementSummary'
  }
}
