import { loadingSlice } from '@redux/reducers/loading'
import { LoadingState } from 'src/@types/manager'
import { AppDispatch } from '@redux/store'

export const createFetchingSelector = (actions: string[]) => (state: LoadingState) => {
  return actions.some((action) => state.fetchingActions && state.fetchingActions[action])
}

export const createErrorSelector = (actions: string[]) => (state: LoadingState) => {
  return actions.some((action) => state.errorActions && state.errorActions[action])
}

export const createLoadingSelectors = (actions: string[]) => {
  return {
    fetchingSelector: createFetchingSelector(actions),
    errorSelector: createErrorSelector(actions),
  }
}

export const clearError = (actions: string[]) => (dispatch: AppDispatch) => {
  dispatch(loadingSlice.actions.clearError(actions))
}

export const clearAllErrors = () => (dispatch: AppDispatch) => {
  dispatch(loadingSlice.actions.clearAllErrors())
}
