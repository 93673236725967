import axios, { AxiosResponse, Method } from 'axios'
import getConfig from 'next/config'
import camelCaseKeys from 'camelcase-keys'

import { Suggestion } from '@openigloo/types'
import { getCurrentUser, getFirebaseAuth } from '../lib/firebase'

const {
  publicRuntimeConfig: { BACKEND_API },
} = getConfig()

const fetchInstance = axios.create({
  baseURL: BACKEND_API,
  // baseURL: "http://127.0.0.1:8000",
  timeout: 30000,
})

type PaginatedAPIFetchResults = {
  count: number
  next: string | null
  previous: string | null
  results: any[]
}

export type APIFetchArgs = {
  path: string
  method?: Method
  params?: any
  headers?: any
  authToken?: string
  data?: any
  noAuthorization?: boolean
}

export const apiFetch = async ({
  path,
  method = 'get',
  params = {},
  data = {},
  headers = {},
  authToken,
  noAuthorization,
}: APIFetchArgs) => {
  headers = {
    ...headers,
    Authorization:
      typeof window !== 'undefined'
        ? `token ${await getFirebaseAuth().currentUser?.getIdToken()}`
        : `token ${authToken}`,
    'x-openigloo-origin': 'manager',
  }
  if(noAuthorization){
    delete headers.Authorization
  }

  try {
    const response = (await fetchInstance.request({
      method,
      url: path,
      params,
      headers,
      data,
    })) as AxiosResponse<any>

    if (method.toLowerCase() !== 'delete' && !response.data) {
      throw new Error('No response from API')
    }

    return camelCaseKeys(response.data, { deep: true })
  } catch (e) {
    throw new Error(
      `Path: ${path}, params: ${JSON.stringify(params)}, data: ${JSON.stringify(data)}, error: ${e}`
    )
  }
}

export const searchAddress = async (addr: string, placeId: string): Promise<Suggestion | null> => {
  try {
    const data = await apiFetch({
      path: `/nyc/buildings/?address=${addr}&place_id=${placeId}`,
    })
    if (data.results) {
      const result = data.results[0]
      return {
        buildingId: result.id,
        address: result.address,
        addressSlug: result.slug,
        productBuildingId: result.productBuildingId,
      } as Suggestion
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}

export const apiFetchPaginated = async (args: APIFetchArgs) => {
  let data = (await apiFetch(args)) as PaginatedAPIFetchResults
  let results = data.results
  while (data.next !== null) {
    args.path = data.next
    data = await apiFetch(args)
    results = results.concat(data.results)
  }
  return results
}
