import { initializeApp, getApps } from 'firebase/app'
import { initializeAuth, browserLocalPersistence } from '@firebase/auth'
import { getStorage } from "firebase/storage";

import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const FIREBASE_WEB_CONFIG = publicRuntimeConfig.FIREBASE_WEB_CONFIG

export const getFirebaseApp = () => {
  if (!getApps().length) {
    return initializeApp(FIREBASE_WEB_CONFIG)
  }
  return getApps()[0]
}

export function getFirebaseAuth() {
  return initializeAuth(getFirebaseApp(), {
    persistence: [browserLocalPersistence],
  })
}


export function getFirebaseStorage() {
  return getStorage(getFirebaseApp());
}

export function getCurrentUser(): Promise<string | null> {
  return new Promise((acc, _) => {
    const auth = getFirebaseAuth()
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken()
        acc(token)
      } else {
        acc(null)
      }
    })
  })
}
