import React, { createContext, useContext, useEffect, useState } from 'react'
import {
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  browserPopupRedirectResolver,
} from 'firebase/auth'

import { getFirebaseAuth } from 'src/lib/firebase'
import {
  setCurrentUserProfile,
  setIsLoggedIn,
  getUserManagedBuildings,
  getUserManagedUnits,
  getUserManagedListings,
  getUserManagedTenants,
  setCityAmenities,
  getUserManagedApplications,
  fetchStripeAccounts,
  fetchUserPermissions,
} from '@redux/actions'
import { useRouter } from 'next/router'
import { Auth, UserProfile } from 'src/data'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { segment } from 'src/lib/analytics'
import { UserPermissions, RoleType } from 'src/@types/manager'
import { UserProfile as UserProfileType } from '@openigloo/types'

interface authContextType {
  signUp: (email: string, password: string) => void
  loginWithPopup: () => void
  loginWithEmailAndPassword: (email: string, password: string) => void
  logout: () => void
  sendPasswordReset: (email: string) => void
  user: UserProfileType
  userPermissions: UserPermissions
  updateUserRole: ({ role: RoleType, description: string }) => void
  authReady: Boolean
}

const authContextDefault: authContextType = {
  signUp: (email, password) => {},
  loginWithPopup: () => {},
  loginWithEmailAndPassword: (email, password) => {},
  logout: () => {},
  sendPasswordReset: (email) => {},
  user: null,
  userPermissions: null,
  updateUserRole: ({ role, description }) => {},
  authReady: false,
}

export const AuthContext = createContext<authContextType | null>(authContextDefault)

const unprotectedRoutes = [
  '/',
  '/login',
  '/listings-quality-policy',
  '/faqs',
  '/manager-report/[contactId]/[page]',
  '/portfolio-perf-report',
]

export const AuthProvider = ({ children }) => {
  const [userPermissions, setUserPermissions] = useState<UserPermissions>({
    id: null,
    role: null,
    isManagingApplications: false,
    isManagingLeases: false,
    isRespondingToComments: false,
  })
  const [user, setUser] = useState(null)
  const [authReady, setAuthReady] = useState(false)

  const provider = new GoogleAuthProvider()
  let auth = getFirebaseAuth()

  const dispatch = useThunkDispatch()
  const router = useRouter()

  useEffect(() => {
    if (auth) {
      setAuthReady(false)
      checkCurrentUser(auth)
    }
  }, [])

  async function checkCurrentUser(auth) {
    await auth.onAuthStateChanged(async (user) => {
      setAuthReady(false)
      if (user) {
        const token = await user.getIdToken()
        const profile = await UserProfile.getUserProfile(token)
        dispatch(setCurrentUserProfile(profile, token))
        setUser(profile)
        const fetchedPermissions = await getUserPermissions(token)
        if (fetchedPermissions && fetchedPermissions.role) {
          getUserData(token, fetchedPermissions)
          // const userBuildings = await UserProfile.getUserManagedBuildings(token)

          // if (!(userBuildings.buildings && userBuildings.buildings.length > 0)) {
          //   router.push('/onboarding')
          // }
        }
      } else {
        await dispatch(setIsLoggedIn(false))
        setUser(null)
        setUserPermissions(null)
        if (!unprotectedRoutes.includes(router.pathname)) {
          router.push(`/login?next=${router.pathname.replace('/', '')}`)
        }
      }
      setAuthReady(true)
    })
  }

  const signUp = async (email, password) => {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password)
      const token = await result.user.getIdToken()
      segment.trackSignupTap('email', email)
      const user = await UserProfile.getUserProfile(token)
      setUser(user)
      dispatch(setCurrentUserProfile(user, token))
      // if (token) onLoginSuccess(token)
    } catch (e) {
      return e
    }
  }

  const loginWithPopup = async () => {
    try {
      const result = await signInWithPopup(auth, provider, browserPopupRedirectResolver)
      const token = await result.user.getIdToken()
      const { uid, email } = result.user
      if (uid && email) {
        segment.trackLoginTap('google', email)
      }
      if (token) {
        onLoginSuccess(token)
      }
    } catch (e) {
      throw Error(e)
    }
  }

  const loginWithEmailAndPassword = async (email, password) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password)
      const token = await result.user.getIdToken()
      if (token) {
        segment.trackLoginTap('email', email)
        onLoginSuccess(token)
      }
    } catch (e) {
      throw Error(e)
    }
  }

  const getUserPermissions = async (token) => {
    try {
      const permissions = await Auth.getPermissions(token)
      setUserPermissions(permissions)
      return permissions
    } catch {
      router.push('/onboarding')
    }
  }

  const updateUserRole = async ({
    role,
    description,
  }: {
    role: RoleType
    description: string | null
  }) => {
    setAuthReady(false)
    const newPermissions = await Auth.setRole(role, description ? description : null)
    setUserPermissions(newPermissions)
    setAuthReady(true)
  }

  const getUserData = async (token, userPermitted) => {
    dispatch(getUserManagedBuildings({ token }))
    dispatch(getUserManagedUnits({ token }))
    dispatch(getUserManagedListings({ token, all: true }))
    dispatch(getUserManagedTenants({ token }))
    dispatch(getUserManagedApplications({ token }))
    dispatch(fetchStripeAccounts(token))
    dispatch(fetchUserPermissions(token))
  }

  const onLoginSuccess = async (token) => {
    console.log('successfully logged in')
    const user = await UserProfile.getUserProfile(token)
    setUser(user)
    dispatch(setCurrentUserProfile(user, token))
    const permissions = await getUserPermissions(token)
    setUserPermissions(permissions)
    if (permissions) {
      await getUserData(token, permissions)
    }
  }

  const logout = async () => {
    setAuthReady(false)
    setUser(null)
    setUserPermissions(null)
    dispatch(setIsLoggedIn(false))
    await auth.signOut()
    router.push('/login?next=home')
  }

  const sendPasswordReset = (email) => {
    sendPasswordResetEmail(auth, email)
  }

  const context = {
    signUp,
    loginWithPopup,
    loginWithEmailAndPassword,
    logout,
    sendPasswordReset,
    authReady,
    userPermissions,
    updateUserRole,
    user,
  }

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return useContext(AuthContext)
}
