import { UserProfile } from '@openigloo/types'
import {
  ManagedCityBuilding,
  ManagedCityListing,
  ManagedCityUnit,
  RatingSummary,
} from 'src/@types/manager'
import { apiFetch, APIFetchArgs, apiFetchPaginated } from './Api'

export type PaginationData = {
  count: number
  next: string
  prev: string
  results: any[]
}

// type UserManagedBuildings = {
//   buildings: ManagedCityBuilding[]
//   paginationData: PaginationData
// }

const USERS_COLLECTION_PATH = 'accounts'
const CURRENT_CITY = 'nyc'

export async function getProfile(authToken = ''): Promise<UserProfile> {
  const requestData = {
    path: 'accounts/',
    authToken,
  }
  return await apiFetch(requestData)
}

export async function updateName(
  userId: string,
  name: string,
  authToken: string
): Promise<UserProfile> {
  const requestData: APIFetchArgs = {
    method: 'PUT',
    path: `accounts/${userId}/set_name/`,
    authToken,
    data: {
      name,
    },
  }

  return await apiFetch(requestData)
}

export async function getUserProfile(authToken: string): Promise<UserProfile> {
  const profile = await apiFetch({ path: `/${USERS_COLLECTION_PATH}/`, authToken })
  return profile as UserProfile
}

const paginatedData = async (authToken, path, page, all) => {
  if (all) {
    const results = await apiFetchPaginated({
      path,
      authToken,
    })
    return { results, count: results.length }
  } else {
    return await apiFetch({
      path,
      params: {
        page,
        page_size: 20
      },
      authToken,
    })
  }
}

export async function getUserManagedBuildings(
  authToken: string,
  page: number = 1,
  all: boolean = false
): Promise<PaginationData | { results: ManagedCityBuilding[] }> {
  const path = `/management/buildings/`
  return paginatedData(authToken, path, page, all)
}

export async function getUserManagedUnits(
  authToken: string,
  page: number = 1,
  all: boolean = false
): Promise<PaginationData | { results: ManagedCityUnit[] }> {
  const path = `/management/units/`
  return paginatedData(authToken, path, page, all)
}

export async function getUserManagedListings(
  authToken: string,
  page: number = 1,
  all: boolean = false
): Promise<PaginationData | { results: ManagedCityUnit[] }> {
  const path = `/management/units/?is_listed=true`
  return paginatedData(authToken, path, page, all)
}
export async function getUserManagedTenants(
  authToken: string,
  page: number = 1,
  all: boolean = false
): Promise<PaginationData | { results: ManagedCityUnit[] }> {
  const path = `/management/units/?is_leased=true`
  return paginatedData(authToken, path, page, all)
}

// TODO: Check this
export async function getUserManagedUnitListings(
  unitId: string,
  authToken: string
): Promise<ManagedCityListing[]> {
  const listings: ManagedCityListing[] = await apiFetchPaginated({
    path: `/management/applications`,
    authToken,
    data: {
      unit_id: unitId,
    },
  })
  return listings
}

// TODO: Check this
// export async function getOngoingLeases(authToken: string) {
//   const leases = await apiFetchPaginated({
//     path: `/tenancies/leases/managed/`,
//     authToken,
//   })
//   return leases
// }

// export async function postSuccessfullCheckout(sessionId: string, authToken = ''): Promise<any> {
//   return await apiFetch({
//     path: `/${USERS_COLLECTION_PATH}/complete_manager_subscription_checkout/`,
//     method: 'POST',
//     data: {
//       checkout_session_id: sessionId,
//     },
//     authToken,
//   })
// }

export async function getManagementSummary(authToken: string) {
  return await apiFetch({
    path: `/management/summary/`,
    authToken,
  })
}

export async function getUserManagedApplications(
  authToken: string,
  page: number = 1,
  all: boolean = false
): Promise<any> {
  const path = `/applications/`
  if (all) {
    const results = await apiFetchPaginated({
      path,
      authToken,
    })
    return { results, count: results.length }
  } else {
    return await apiFetch({
      path,
      params: {
        page,
      },
      authToken,
    })
  }
}

// export async function getAllUserManagedBuildings(
//   authToken: string
// ): Promise<ManagedCityBuilding[]> {
//   return await apiFetchPaginated({
//     path: `/management/buildings/`,
//     authToken,
//   })
// }

// export async function getAllUserManagedApplications(authToken: string): Promise<any> {
//   return await apiFetchPaginated({
//     path: `/applications/`,
//     authToken,
//   })
// }

// export async function getAllUserManagedUnits(authToken: string): Promise<any> {
//   return await apiFetchPaginated({
//     path: `/management/units/`,
//     authToken,
//   })
// }

export async function getRecentReviews(authToken: string) {
  return await apiFetch({
    path: `/management/buildings/recent_reviews/`,
    authToken,
  })
}

export async function getRatingsSummary(authToken: string): Promise<RatingSummary> {
  return await apiFetch({
    path: `/management/buildings/ratings_summary/`,
    authToken,
  })
}
